import { generalUtils } from "bob-group-ui-framework";
import { paymentMethodsType } from "interfaces/paymentMethodsResponse.interface";

// Copied from google API developer guide
const googleComponentForm: any = {
  street_number: "short_name",
  route: "long_name",
  sublocality_level_1: "long_name",
  sublocality_level_2: "long_name",
  locality: "long_name",
  administrative_area_level_2: "short_name",
  administrative_area_level_1: "long_name",
  country: "short_name",
  postal_code: "short_name"
};

const emptyAddress = {
  company: "",
  address: "",
  local_area: "",
  city: "",
  code: "",
  zone: "",
  country: "",
  entered_address: "",
  latitude: 0,
  longitude: 0,
  lat: 0,
  lng: 0
};

const provinces = [
  { label: "Eastern Cape", value: "Eastern Cape" },
  { label: "Free State", value: "Free State" },
  { label: "Gauteng", value: "Gauteng" },
  { label: "KwaZulu-Natal", value: "KwaZulu-Natal" },
  { label: "Limpopo", value: "Limpopo" },
  { label: "Mpumalanga", value: "Mpumalanga" },
  { label: "Northern Cape", value: "Northern Cape" },
  { label: "North West", value: "North West" },
  { label: "Western Cape", value: "Western Cape" }
];

function emptyAddressObj() {
  return generalUtils.clone(emptyAddress);
}

const emptyContact = {
  name: "",
  mobile_number: "",
  email: ""
};

function emptyContactObj() {
  return generalUtils.clone(emptyContact);
}

function currency() {
  return "R";
}

function currencyLong() {
  return "ZAR";
}

const chargeTypes = [
  {
    label: "%",
    value: "percentage"
  },
  {
    label: currencyLong(),
    value: "flat"
  },
  {
    label: currency() + "/kg",
    value: "perKg"
  }
];

const vatTypesRateAdjustments = [
  {
    label: "Standard VAT",
    value: "standard"
  },
  {
    label: "Zero-rated VAT",
    value: "zero"
  },
  {
    label: "VAT exempted",
    value: "exempted"
  }
];

const vatTypesServiceLevels = [
  {
    label: "Standard VAT",
    value: "standard"
  },
  {
    label: "Zero-rated VAT",
    value: "zero"
  }
];

const timeBasedRateAdjustmentTypes = [
  {
    label: "Collection",
    value: "collection"
  },
  {
    label: "Delivery",
    value: "delivery"
  }
];

const estimateTimeTypes = {
  h: {
    label: "Hour(s)",
    labelShort: "hrs",
    value: "h"
  },
  d: {
    label: "Day(s)",
    labelShort: "days",
    value: "d"
  }
};

const tagConfig: any = {
  account: {
    title: "Account tags",
    url: "/tags",
    postBody: { type: "account" },
    getArgs: { type: "account" }
  },
  manual_eft: {
    title: "Proof of payment manual EFT tags",
    url: "/tags",
    postBody: { type: "manual_eft" },
    getArgs: { type: "manual_eft" }
  }
};

const defaultTablePageSize = 20;
const defaultTablePageSizeSmall = 10;

const allBillingTransactionTypes = [
  {
    label: "Fee",
    value: "fee"
  },
  {
    label: "Fee reversal",
    value: "fee-reversal"
  },
  {
    label: "Payment received",
    value: "payment-received"
  },
  {
    label: "Payment received reversal",
    value: "payment-received-reversal"
  },
  {
    label: "Payment credit",
    value: "payment-credit"
  },
  {
    label: "Payment debit",
    value: "payment-debit"
  },
  {
    label: "Payout",
    value: "payout"
  },
  {
    label: "Payout reversal",
    value: "payout-reversal"
  },
  {
    label: "Admin debit",
    value: "admin-debit"
  },
  {
    label: "Admin credit",
    value: "admin-credit"
  },
  {
    label: "Promotional credit",
    value: "promotional-credit"
  },
  {
    label: "Promotional debit",
    value: "promotional-debit"
  },
  {
    label: "Balance adjustment credit",
    value: "balance-adjustment-credit"
  },
  {
    label: "Balance adjustment debit",
    value: "balance-adjustment-debit"
  },
  {
    label: "Bad debt write-off",
    value: "bad-debt-write-off"
  },
  {
    label: "Bad debt write-off reversal",
    value: "bad-debt-write-off-reversal"
  }
];

const accountTypes = [
  {
    label: "Prepaid",
    value: "prepaid"
  },
  {
    label: "Postpaid",
    value: "postpaid"
  }
];

const paymentIntentStatuses = [
  {
    label: "Paid",
    value: "paid"
  },
  {
    label: "Unpaid",
    value: "unpaid"
  },
  { label: "Canceled", value: "canceled" },
  {
    label: "Refunded",
    value: "refunded"
  }
];

const instantEFTPaymentStatuses = [
  {
    label: "Paid",
    value: "paid"
  },
  {
    label: "Unpaid",
    value: "unpaid"
  }
];

const paymentStatuses = [
  {
    label: "Success",
    value: "success"
  },
  {
    label: "Pending",
    value: "pending"
  },
  {
    label: "Failed",
    value: "failed"
  },
  {
    label: "Reversed",
    value: "reversed"
  }
];

const savedCardStatuses = [
  {
    label: "Complete",
    value: "complete"
  },
  {
    label: "Pending",
    value: "pending"
  },
  {
    label: "Failed",
    value: "failed"
  }
];

const capitecPayPaymentStatuses = [
  {
    label: "Pending",
    value: "PENDING"
  },
  {
    label: "Success",
    value: "SUCCESS"
  },
  {
    label: "Declined",
    value: "DECLINED"
  },
  {
    label: "Timeout",
    value: "TIMEOUT"
  },
  {
    label: "Failed",
    value: "FAILED"
  },
  {
    label: "Fraud",
    value: "FRAUD"
  }
];

const popEftStatuses = [
  {
    label: "Accepted",
    value: "accepted"
  },
  {
    label: "Rejected",
    value: "rejected"
  },
  {
    label: "Unmatched",
    value: "unmatched"
  },
  {
    label: "Duplicate",
    value: "duplicate"
  },
  {
    label: "Force accepted",
    value: "force-accepted"
  }
];

const needsInterventionFilterOptions = [
  {
    label: "All",
    value: "all"
  },
  {
    label: "Needs intervention",
    value: "needs_intervention"
  },
  {
    label: "No intervention needed",
    value: "no_intervention_needed"
  }
];

const invoiceFrequencies = [
  { label: "Monthly", value: "monthly" },
  { label: "Weekly", value: "weekly" },
  { label: "Daily", value: "daily" }
];

const daysOfWeek = [
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
  { label: "Sunday", value: 0 }
];

const accountStatuses = [
  {
    label: "Closed",
    value: "closed"
  },
  {
    label: "Open",
    value: "open"
  },
  {
    label: "Auto-generated",
    value: "temp"
  }
];

const s3Buckets = {
  accounts: "accounts",
  billing: "billing"
};

const s3Folders = {
  addressBook: "address-books"
};

const browserVersions = {
  chrome: "88.0.4324", // 2021-01-19
  crios: "88.0.4324", // 2021-01-19
  safari: "14.0.3", // 2021-02-01
  ios: "14.0.3", // 2021-02-01
  firefox: "91.0", // 2021-08-10
  fxios: "91.0", // 2021-08-10
  edge: "87.0.664.75", // 2021-01-07
  "edge-chromium": "87.0.664.75", // 2021-01-07
  "edge-ios": "87.0.664.75" // 2021-01-07
};

const absoluteQueryOptions = [
  { label: "Specify date range", value: "date_range" },
  { label: "Specify month", value: "month" }
];

const statementQueryOptions = [
  { label: "Specify date range", value: "date_range" },
  { label: "Specify month", value: "month" }
];

const bankingDetailsAccountTypes = [
  { label: "Cheque / current", value: "Cheque / current" },
  { label: "Savings", value: "Savings" },
  { label: "Transmission", value: "Transmission" }
];

const capitecPayIdentificationMethods = [
  { label: "Mobile number", value: "CELLPHONE" },
  { label: "South African ID number", value: "IDNUMBER" },
  { label: "Account number", value: "ACCOUNTNUMBER" }
];

const paymentMethods: {
  label: string;
  value: paymentMethodsType;
  hasAccountSetting?: boolean;
}[] = [
  {
    label: "Account balance",
    value: "account-balance"
  },
  {
    label: "Statement",
    value: "statement"
  },
  {
    label: "Credit or debit card",
    value: "credit-card",
    hasAccountSetting: true
  },
  {
    label: "Instant EFT",
    value: "instant-eft",
    hasAccountSetting: true
  },
  {
    label: "Manual EFT",
    value: "manual-eft",
    hasAccountSetting: true
  },
  {
    label: "PayShap",
    value: "pay-shap",
    hasAccountSetting: true
  },
  {
    label: "Capitec Pay",
    value: "capitec-pay",
    hasAccountSetting: true
  },
  {
    label: "Scan to Pay",
    value: "scan-to-pay",
    hasAccountSetting: true
  },
  {
    label: "Nedbank Direct EFT",
    value: "nedbank-direct-eft",
    hasAccountSetting: true
  }
];

const bankDetailsVerificationStatuses = [
  { label: "Approved", value: "approved" },
  { label: "Pending", value: "pending" },
  { label: "Declined", value: "declined" }
];

// These bank details verification declined reasons will be displayed in an email so "value" is sent as sentence case
const bankDetailsVerificationDeclinedReason = [
  { label: "Missing bank confirmation letter", value: "Missing bank confirmation letter" },
  { label: "Missing billing address details", value: "Missing billing address details" },
  { label: "Missing billing contact details", value: "Missing billing contact details" }
];

const canReceivePayments = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" }
];

const googleTrackingId = "G-QLPJ8V74DB";

const vatPercentage = 1.15;

const googleMapsAPIKey = "AIzaSyC95_4Ljxyv_fTrj7UgUB2wzUMlf5K03Uo";

export {
  googleMapsAPIKey,
  vatPercentage,
  accountStatuses,
  daysOfWeek,
  invoiceFrequencies,
  accountTypes,
  defaultTablePageSize,
  defaultTablePageSizeSmall,
  timeBasedRateAdjustmentTypes,
  paymentIntentStatuses,
  instantEFTPaymentStatuses,
  paymentStatuses,
  savedCardStatuses,
  capitecPayPaymentStatuses,
  popEftStatuses,
  needsInterventionFilterOptions,
  tagConfig,
  currency,
  currencyLong,
  chargeTypes,
  vatTypesRateAdjustments,
  vatTypesServiceLevels,
  estimateTimeTypes,
  googleComponentForm,
  emptyAddressObj,
  emptyContactObj,
  provinces,
  s3Buckets,
  s3Folders,
  allBillingTransactionTypes,
  browserVersions,
  absoluteQueryOptions,
  statementQueryOptions,
  bankingDetailsAccountTypes,
  capitecPayIdentificationMethods,
  paymentMethods,
  bankDetailsVerificationStatuses,
  googleTrackingId,
  bankDetailsVerificationDeclinedReason,
  canReceivePayments
};
