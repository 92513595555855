import moment from "moment";
import config from "config";
import * as roleUtils from "utils/roleUtils";
import * as authUtils from "utils/authUtils";
import axios from "axios";
import * as localConstants from "constantsAndDefaults";
import { Link } from "react-router-dom";
import { IUser } from "interfaces/user.interface";
import { IObjectKeys } from "interfaces/object.interface";
import { IResult } from "interfaces/result.interface";
import { IAccount } from "interfaces/account.interface";
import { IStore } from "interfaces/store.interface";
import { ReactElement, Suspense, lazy } from "react";
import {
  InfoButton,
  Message,
  generalUtils,
  dateUtils,
  Card,
  Label,
  LabelWithValue,
  Loader
} from "bob-group-ui-framework";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IBankConfig } from "interfaces/bank.interface";
const { detect } = require("detect-browser");

// Used to check current app version
import packageJson from "../../package.json";
import { bankConfigs } from "bankConfig";
import { IPaymentIntent } from "interfaces/paymentIntent.interface";
const JsonViewer = lazy(() => import("components/JSONViewer"));

const activeRequests: any = {};
let requestIDCounter = 0;

// Ensure that the response from the latest request is used:
// If two identical requests are made in the order A then B, and A returns slower than B does, A will be killed and the results from B will be used.
// If a returns before B, the results from A will be used until B returns a result

async function killDuplicateRequests(activeRequest: {
  body: any;
  method: string;
  requestID: number;
  url: string;
  requestTimeStamp: Date;
}) {
  try {
    let requestIDs: any[] = [];
    if (
      activeRequests &&
      activeRequest &&
      activeRequest?.url &&
      activeRequests[activeRequest?.url]
    ) {
      requestIDs = Object.keys(activeRequests[activeRequest?.url]);
    }

    if (activeRequest) {
      requestIDs.forEach(async requestID => {
        const req = activeRequests[activeRequest?.url][requestID];
        if (req.requestID === activeRequest.requestID) {
          // Remove request from active requests
          delete activeRequests[activeRequest?.url][requestID];
        } else if (
          activeRequest?.url === req?.url &&
          activeRequest.method === req.method &&
          JSON.stringify(activeRequest.body) === JSON.stringify(req.body)
        ) {
          // // kill all other requests with the same parameters & remove from active requests
          if (activeRequest.requestTimeStamp < req.requestTimeStamp) {
            // Abort only if request was made before the active request and has not yet returned a response
            await req.abortController.abort();
            console.warn("Aborted duplicate request", `${req.method}: ${req?.url}`);
            if (
              activeRequests &&
              activeRequest?.url &&
              requestID &&
              typeof activeRequests === "object"
            ) {
              delete activeRequests[activeRequest?.url][requestID];
            }
          }
        }
        if (
          activeRequests &&
          typeof activeRequests === "object" &&
          activeRequest &&
          activeRequest?.url &&
          Object.keys(activeRequests[activeRequest?.url]).length === 0
        ) {
          delete activeRequests[activeRequest?.url];
        }
      });
    }
  } catch (e) {
    console.log(e);
  }
}

// Signed request call an API Gateway endpoint and signs the request signature version 4 with the given credentials
async function signedRequest(
  store: IStore,
  endpoint: string,
  method: string,
  args?: any,
  headers?: any,
  shouldIgnore401?: boolean,
  retryCounter?: number
): Promise<any> {
  const maxRetries = 3;
  const retryCounterValue: number = retryCounter ?? 0;

  if (!args) {
    args = {};
  }

  const accessToken = authUtils.getAccessToken();

  const v1Endpoints = ["/login", "/time"];

  if (accessToken && v1Endpoints.indexOf(endpoint) === -1 && endpoint.indexOf("v2") === -1) {
    endpoint = `/v2${endpoint}`;
  }

  if (!headers) {
    headers = { "Content-Type": "application/json" };
  }

  headers["client-version"] = "web-" + packageJson.version;

  method = method.toUpperCase();

  try {
    let body = null;
    let path = endpoint;

    // Build query arguments from args for get
    if (method === "GET") {
      if (store && store.impersonated_user_id) {
        args.request_id = store.impersonated_user_id;
      }

      path += generalUtils.serialize(args);
      // Else use the args for the request body
    } else {
      body = JSON.stringify(args);

      if (store && store.impersonated_user_id) {
        path += generalUtils.serialize({
          request_id: store.impersonated_user_id
        });
      }
    }

    let url = `https://${config.api}${path}`;
    if (config.api.indexOf("localhost") >= 0) {
      url = `http://${config.api}${path}`;
    }

    // Options for the signature version 4 signing
    const opts: any = {
      method: method,
      path: path,
      headers: headers,
      hostname: config.api,
      url: url,
      region: config.region,
      service: "execute-api"
    };

    if (body !== null) {
      opts.body = body;
    }

    if (accessToken) {
      headers["Authorization"] = "bearer " + accessToken;
    }

    let request: any;
    const signedRequestObj: any = { headers, url };

    const init: { method: string; headers: any; body: any; signal?: any } = {
      method: method,
      headers: signedRequestObj?.headers,
      body: body
    };

    if (method === "GET") {
      const requestID = requestIDCounter;
      requestIDCounter++;
      const abortController = new AbortController();
      request = {
        url: signedRequestObj.url,
        method,
        abortController,
        body,
        requestID,
        requestTimeStamp: new Date()
      };
      if (!activeRequests[url]) {
        activeRequests[url] = {};
      }
      activeRequests[url][requestID] = request;
      init.signal = abortController.signal;
    }

    const response = await fetch(signedRequestObj.url, init);

    if (method === "GET") {
      killDuplicateRequests(request);
    }

    if (!response.ok) {
      !shouldIgnore401 && checkTokenValidity(store, response, endpoint);
      generalUtils.checkMaintenanceMode(store, response);
      generalUtils.checkTokenExpired(store, response);
      generalUtils.checkAccountClosed(store, response);

      // Error message is in response body as text
      const errorMsg = await response.text();

      if (errorMsg.toLowerCase().indexOf("service unavailable") > -1) {
        if (retryCounterValue < maxRetries) {
          return new Promise(async resolve => {
            setTimeout(async () => {
              const res = await signedRequest(
                store,
                endpoint,
                method,
                args,
                headers,
                shouldIgnore401,
                retryCounterValue + 1
              );
              resolve(res);
            }, 1000 * (retryCounterValue + 1)); // Progressively longer timeouts as retries progress
          });
        } else {
          console.log("Service unavailable, maximum retries reached.");
        }
      }

      // Don't change this method without changing the backend
      if (errorMsg.indexOf("You're not logged in. Please log in and try again.") !== -1) {
        // Redirect back to login page if not logged in
        window.location.href = "/login";
        return;
      }

      return { ok: false, error: new Error(errorMsg), code: response.status };
    }

    let data = "";
    try {
      data = await response.text();
      data = JSON.parse(data);
    } catch (e) {
      // Assume it is text
    }

    return { ok: true, data, code: response.status };
  } catch (e: any) {
    if (e?.name === "AbortError") {
      return { ok: false, error: undefined };
    }
    return { ok: false, error: generalUtils.getError(e, true) };
  }
}

function checkTokenValidity(store: IStore, response: any, endpoint: string) {
  if (response.status === 401 && endpoint !== "/login") {
    // Only log out if not refreshing tokens
    if (
      localStorage.getItem("isRefreshing") !== null &&
      // @ts-ignore
      !JSON.parse(localStorage.getItem("isRefreshing"))
    ) {
      authUtils.logOut(store);
    }
  }
}

async function getGeneralData(store: IStore, shouldIgnore401: boolean): Promise<any> {
  let error = null;
  let data: IObjectKeys = {};

  try {
    const result: IResult = await signedRequest(
      store,
      "/general-data",
      "GET",
      undefined,
      undefined,
      shouldIgnore401
    );

    if (result.ok) {
      data = result.data;
    } else {
      stopImpersonating(store);
      error = "Could not load general data.";
    }
  } catch (e) {
    error = "Could not load general data.";
    stopImpersonating(store);
  }

  if (data["account_settings"]) {
    const settings: any = {};

    for (const s of data["account_settings"]) {
      try {
        settings[s.setting] = JSON.parse(s.value);
      } catch (_) {
        // Not json
        settings[s.setting] = s.value;
      }
    }
    data["account_settings"] = settings;
  }

  return { data, error };
}

async function getSystemConfig(store: IStore): Promise<any> {
  const systemConfig: any = {};
  let error = null;

  try {
    const result: IResult = await signedRequest(store, "/config", "GET");

    if (result.ok) {
      if (result.data.config) {
        for (const configObject of result.data.config) {
          try {
            configObject.value = JSON.parse(configObject.value);
          } catch (_) {
            // do nothing
          }
          systemConfig[configObject.key] = configObject;
        }
      }
    } else {
      error = generalUtils.getError(result, null);
    }
  } catch (e) {
    error = generalUtils.getError(e, null);
  }

  return { systemConfig, error };
}

async function setUserSetting(store: IStore, key: any, value: any): Promise<any> {
  const result: IResult = await signedRequest(
    store,
    "/users/settings",
    "POST",
    {
      setting: key,
      value: value
    },
    null
  );

  if (result.ok) {
    let userSettings = store.get("user_settings");
    if (!userSettings) {
      userSettings = {};
    }
    if (result?.data?.setting) {
      userSettings[result.data.setting] = result.data.value;
    }
    store.set("user_settings", userSettings);
    return undefined;
  } else {
    return generalUtils.getError(result, null);
  }
}

function getStatusClassNames(status: string): string {
  if (["unmatched"].indexOf(status) >= 0) {
    return "status-badge orange-status";
  } else if (["accepted", "force-accepted", "refunded"].indexOf(status) >= 0) {
    return "status-badge green-status";
  }
  return "status-badge red-status";
}

function getInvoiceStatusClassNames(status: string): string {
  if (status.toLowerCase() === "unpaid") {
    return "status-badge red-status";
  }
  return "status-badge green-status";
}

function getPaymentIntentStatusClassNames(status: string): string {
  return status === "paid" || status === "refunded"
    ? "status-badge green-status"
    : status === "refund_pending"
    ? "status-badge yellow-status"
    : "status-badge red-status";
}

function getAccountNoteTypeClassNames(type: string): string {
  if (type === "sales") {
    return "notes-badge green-note";
  }
  if (type === "private") {
    return "notes-badge fuschia-note";
  }
  return "notes-badge iris-note";
}

function getPayoutRequestStatusClassNames(status: string): string {
  if (status === "rejected") {
    return "status-badge red-status";
  }
  if (status === "processed") {
    return "status-badge green-status";
  }
  return "status-badge blue-status";
}

function getBalanceClassNames(accountBalance: number, small: boolean): string {
  const hasNegativeBalance = accountBalance < 0;
  const className =
    "rounded-full text-center px-5 py-2 font-bold " +
    (hasNegativeBalance ? "bg-pink-100 text-pink " : "bg-green-100 text-green ");
  if (small) {
    return className + "mt-2 text-sm ";
  }
  return className + "min-h-9 ";
}

function activeUser(props: { store: IStore }): IUser | undefined {
  if (props && props.store) {
    return activeUserFromStore(props.store);
  }

  return undefined;
}

function activeUserFromStore(store: IStore): IUser {
  return store.logged_in_user;
}

function addFiltersToArgs(
  filters: any,
  args: any,
  wildCardedColumns?: string[],
  dontChangeDates?: boolean
) {
  if (!wildCardedColumns) wildCardedColumns = [];

  Object.keys(filters).forEach(key => {
    if (filters[key] && (!Array.isArray(filters[key]) || filters[key].length > 0)) {
      let val = filters[key];

      if (key === "start_date" || key === "date" || key === "from_invoice_date") {
        if (dontChangeDates) {
          val = dateUtils.pgFormatDate(moment(val));
        } else {
          val = dateUtils.pgFormatDate(moment(val).startOf("day"));
        }
      }

      if (key === "end_date" || key === "to_invoice_date") {
        if (dontChangeDates) {
          val = dateUtils.pgFormatDate(moment(val));
        } else {
          val = dateUtils.pgFormatDate(moment(val).endOf("day"));
        }
      }

      if (Array.isArray(filters[key])) {
        args[key] = JSON.stringify(val);
        // @ts-ignore
      } else if (wildCardedColumns.indexOf(key) === -1) {
        args[key] = val;
      } else {
        // Encode with % wildcard (postgres) at the begining and end of the argument
        // The encoding is need because args are put into the query URL
        args[key] = "%" + val + "%";
      }
    }
  });
}

function addSortingToArgs(args: any, tableState: any) {
  if (tableState.sorted) {
    if (tableState.sorted.length) {
      args.order_by = tableState.sorted[0].id;
      args.order = tableState.sorted[0].desc ? "DESC" : "ASC";
    }
  }
}

function invoiceNumber(val: any): string {
  if (!val) return "–";
  if (val.toString().includes("INV")) return val;
  return "INV" + generalUtils.padLeadingZeros(val, 6);
}

function creditNoteNumber(val: any): string {
  if (!val) return "–";
  if (val.toString().includes("CR")) return val;
  return "CR" + generalUtils.padLeadingZeros(val, 6);
}

async function generateAndOpenS3Resource(
  store: IStore,
  url: string,
  args: any,
  method: string = "GET"
): Promise<any> {
  try {
    const response: IResult = await signedRequest(store, url, method, args, {
      "Content-Type": "application/json"
    });

    if (response.ok && response.data) {
      const data: {
        url?: string;
        filename?: string;
        bucket?: string;
        email?: string;
        queued?: boolean;
        message?: string;
      } = response.data;
      if (data.url) {
        window.open(data.url);
      } else if (data.queued) {
        store.emitter.emit("showAlert", {
          title: "File download",
          body: `We see that you are trying to download a large number of items. Let us rather send them to ${data.email} once the file has been generated.`,
          showOkButton: true,
          okButtonVariant: "primary",
          return: () => {}
        });
      }
    } else if (
      !response.ok &&
      response.error.message.indexOf("User does not have an email address") >= 0
    ) {
      store.emitter.emit("showAlert", {
        title: "File download",
        body: "Please associate an email address with your account before attempting to download a large number of items.",
        showOkButton: true,
        return: () => {}
      });
    } else {
      return generalUtils.getError(response, null);
    }
  } catch (e) {
    return generalUtils.getError(e, null);
  }
}

function getDocIDLink(row: any, props: { store: IStore }): ReactElement | string {
  if (row.original.invoice_id) {
    return (
      // @ts-ignore
      <Link
        to={
          "/" +
          (roleUtils.userHasAdminSystemAccess(props) ? "accounts/" : "account/") +
          "invoices/" +
          row.original.invoice_id
        }
        className="color"
      >
        {invoiceNumber(row.original.invoice_id)}
      </Link>
    );
  }
  if (row.original.credit_note_id) {
    return (
      // @ts-ignore
      <Link
        to={
          "/" +
          (roleUtils.userHasAdminSystemAccess(props) ? "accounts/" : "account/") +
          "credit-notes/" +
          row.original.credit_note_id
        }
        className="color"
      >
        {creditNoteNumber(row.original.credit_note_id)}
      </Link>
    );
  }

  return "–";
}

async function getAccount(
  store: IStore,
  accountID?: number,
  shouldIgnore401?: boolean
): Promise<any> {
  if (!accountID) {
    const activeUser: IUser = activeUserFromStore(store);
    if (!activeUser || !activeUser.account_id) return {};

    accountID = activeUser.account_id;
  }

  try {
    const result: IResult = await signedRequest(
      store,
      "/accounts",
      "GET",
      {
        id: accountID
      },
      undefined,
      shouldIgnore401
    );

    if (result.ok && result.data.accounts && result.data.accounts.length > 0) {
      return { account: result.data.accounts[0] };
    } else {
      return { error: generalUtils.getError(result, null) };
    }
  } catch (e) {
    return { error: generalUtils.getError(e, null) };
  }
}

async function updateAccount(store: IStore, account: IAccount): Promise<any> {
  try {
    const result: IResult = await signedRequest(store, "/accounts", "PATCH", account, null);

    if (result.ok) {
      const currentAccount = store.get("account");
      const updatedAccount = { ...currentAccount, ...result.data };
      // Update local state
      if (account.id === currentAccount.id) {
        store.set("account", updatedAccount);
      }
      return { account: updatedAccount };
    } else {
      return { error: generalUtils.getError(result, null) };
    }
  } catch (e) {
    return { error: generalUtils.getError(e, null) };
  }
}

function isAccountUser(props: { store: IStore }) {
  const { impersonated_user_id } = props.store;
  const activeUser = activeUserFromStore(props.store);

  return impersonated_user_id || (activeUser && activeUser.account_id) || !activeUser;
}

function isMaintenanceModePage() {
  return window.location.pathname === "/maintenance";
}

function isAccountClosedPage() {
  return window.location.pathname === "/account-closed";
}

function paymentHasBeenAccepted(popEft: any): boolean {
  return (
    popEft.status === "force-accepted" ||
    popEft.status === "accepted" ||
    popEft.status === "unmatched"
  );
}

async function checkTime(store: IStore, message?: string) {
  try {
    const response = await signedRequest(store, "/time", "GET");
    if (response.ok && response.data) {
      const serverTime = response.data;
      const now = new Date();

      const duration = moment.duration(moment(now).diff(serverTime));
      const minutes = duration.asMinutes();

      const serverTimezoneOffset = moment(serverTime).utcOffset("+0200").format("Z");
      const localTimezoneOffset = moment(now).format("Z");

      if (Math.abs(minutes) > 5) {
        store.emitter.emit("showAlert", {
          title: "Are you a time traveller?",
          body: (
            <div>
              <div className="flex justify-center">
                <FontAwesomeIcon icon="clock" className="text-primary mb-8 mt-4" size="4x" />
              </div>
              <div className="mb-4">
                {message ?? "Your time is out of sync with our server time"}.
              </div>

              <div>
                Our current server time is{" "}
                <span className="font-bold">
                  {moment(serverTime).utcOffset("+0200").format("YYYY-MM-DD HH:mm Z")}
                </span>
                , please set your date and time to{" "}
                <span className="font-bold text-primary inline-flex">
                  {moment(serverTime).format("YYYY-MM-DD HH:mm Z")}
                  <InfoButton>
                    <div className="mb-4">
                      This is your local date and time that correspond to our server time. If this
                      time seems incorrect, please ensure that the correct timezone is set for your
                      device.
                    </div>
                    {serverTimezoneOffset !== localTimezoneOffset && (
                      <Message.Info>
                        <div className="inline-flex space-x-4 items-center">
                          <FontAwesomeIcon icon="info-circle" />
                          <div> Your device is set to a different timezone than our server. </div>
                        </div>
                      </Message.Info>
                    )}
                  </InfoButton>
                </span>
              </div>
            </div>
          ),
          showOkButton: true,
          okButtonText: "Ok",
          okButtonVariant: "primary",
          showCancelButton: false,
          return: () => {}
        });
        return { ok: false };
      } else {
        return { ok: true };
      }
    } else {
      return { e: response.error };
    }
  } catch (e) {
    return { e };
  }
}

async function loadFromS3(store: IStore, fileName: string, folder: string, bucket: string) {
  const result = await signedRequest(store, "/s3-url/download", "GET", {
    file_name: [fileName],
    folder: folder,
    bucket: bucket
  });
  if (result.ok) {
    return result.data;
  } else {
    return { error: generalUtils.getError(result) };
  }
}

async function uploadToS3(
  store: IStore,
  file: { name: string },
  otherArguments: any,
  contentType: string
) {
  const result = await signedRequest(store, "/s3-url/upload", "GET", {
    file_name: file.name,
    ...otherArguments
  });
  if (result.ok) {
    const url = result.data.url;
    const fileName = result.data.file_name;
    const headers = result.data.headers ?? {};
    try {
      headers["Content-Type"] = contentType;
      const response = await axios.put(url, file, { headers: headers });
      if (response.status === 200) {
        return { url, file_name: fileName };
      } else {
        const error = generalUtils.getError(response);
        return { error };
      }
    } catch (e) {
      const error = generalUtils.getError(e);
      return { error };
    }
  }
  const error = generalUtils.getError(result);
  return { error };
}

function checkBrowserVersion(store: IStore) {
  try {
    const browser = detect();

    const browserDetails = { name: browser.name, version: browser.version };
    const activeUser = activeUserFromStore(store);
    const userSettings = store.get("user_settings");
    const ignoreVersion = activeUser
      ? userSettings.ignore_browser_outdated
      : localStorage.getItem("ignore_browser_outdated");

    if (
      generalUtils.isBrowserOutdated(browserDetails.name, browserDetails.version) &&
      !ignoreVersion
    ) {
      store.emitter.emit("showAlert", {
        title: "Your browser is out of date",
        body: (
          <div>
            <div className="flex justify-center">
              <FontAwesomeIcon
                icon={generalUtils.getBrowserIcon(browserDetails.name)}
                className="text-primary mb-8 mt-4"
                size="4x"
              />
            </div>

            <div className="flex flex-col space-y-4">
              <div>
                It seems like your browser is out of date. Some features might not work as expected.
              </div>
              <div>In order to have the best experience, please update your browser.</div>
            </div>
          </div>
        ),
        showCancelButton: true,
        showOkButton: true,
        okButtonText: "Don't remind me again",
        cancelButtonText: "Ok",
        okButtonVariant: "danger",
        return: (val: any) => {
          if (val) {
            if (activeUser) {
              setUserSetting(store, "ignore_browser_outdated", "true");
            } else {
              localStorage.setItem("ignore_browser_outdated", "true");
            }
          }
        }
      });
    }
  } catch (e) {
    console.log("checkBrowserVersion: ", e);
  }
}

function getBanksConfig(store: IStore): IBankConfig[] {
  const config = store.get("system_config") ?? [];
  let availableBanks: IBankConfig[] = [];
  config?.forEach((c: any) => {
    if (c.key === "available_banks") {
      availableBanks = JSON.parse(c.value);
      for (let i = 0; i < availableBanks.length; i++) {
        bankConfigs.forEach((b: any) => {
          if (availableBanks[i].value === b.value) {
            availableBanks[i] = { ...availableBanks[i], ...b };
          }
        });
      }
    }
  });
  return availableBanks;
}

function stopImpersonating(store: IStore) {
  if (store.impersonated_user_id) {
    store.set("impersonated_user_id", undefined);
    localStorage.removeItem("impersonated_user_id");
    window.location.href = `/accounts/${store.account.id}`;
  }
}

function formatAccountNumber(string: string, maskValue?: boolean) {
  if (typeof string === "string" && string.length > 0) {
    const size = 4;
    const arr = [];
    let counter = 0;
    let stars = "";
    for (let i = 0; i < size; i++) {
      stars = stars + "*";
    }

    while (counter < string.length) {
      if (maskValue) {
        if (counter < size || counter >= string.length - size) {
          arr.push(
            string.substring(counter, counter + size > string.length ? undefined : counter + size)
          );
        } else {
          arr.push(stars);
        }
      } else {
        arr.push(
          string.substring(counter, counter + size > string.length ? undefined : counter + size)
        );
      }

      counter += size;
    }

    return arr.join(" ");
  }

  return string;
}

function getCreditCardType(creditCardNumber: string): "visa" | "masterpass" | null {
  // Visa cards begin with a 4 and have 13 or 16 digits.
  // Mastercard cards (masterpass) begin with a 5 and has 16 digits.
  if (
    creditCardNumber[0] === "4" &&
    (creditCardNumber.length === 13 || creditCardNumber.length === 16)
  ) {
    return "visa";
  } else if (creditCardNumber[0] === "5" && creditCardNumber.length === 16) {
    return "masterpass";
  }
  return null;
}

function getCommunicationLogStatusClassNames(status: string): string {
  if (status === "success") {
    return "status-badge green-status";
  } else if (
    status === "failed" ||
    status === "bounced" ||
    status === "complaint" ||
    status === "do-not-disturb"
  ) {
    return "status-badge red-status";
  }

  return "status-badge blue-status";
}

function getCapitecPayPaymentStatusClassNames(status: string): string {
  if (status === "SUCCESS" || status.toUpperCase() === "REFUNDED") {
    return "status-badge green-status";
  }
  if (status === "PENDING") {
    return "status-badge blue-status";
  } else if (
    status === "DECLINED" ||
    status === "TIMEOUT" ||
    status === "FAILED" ||
    status === "FRAUD"
  ) {
    return "status-badge red-status";
  }

  return "status-badge blue-status";
}

function getSavedCardStatusClassName(status: string): string {
  return status === "complete"
    ? "status-badge green-status"
    : status === "pending"
    ? "status-badge blue-status"
    : "status-badge red-status";
}

function getPaymentStatusClassName(status?: string): string {
  return status === "success" || status === "paid" || status === "refunded"
    ? "status-badge green-status"
    : status === "failed" || status === "unpaid"
    ? "status-badge red-status"
    : status === "reversed"
    ? "status-badge pink-status"
    : status === "refund_pending"
    ? "status-badge yellow-status"
    : "status-badge blue-status";
}

function getCreditCardPaymentTypeClassName(type?: string): string {
  return type === "payment"
    ? "status-badge green-status"
    : type === "reversal"
    ? "status-badge pink-status"
    : "status-badge blue-status";
}

function getPOPBankVerificationClassName(type?: string): string {
  return type === "passed"
    ? "status-badge green-status"
    : type === "failed"
    ? "status-badge pink-status"
    : "status-badge blue-status";
}

function getHumanReadablePaymentMethod(paymentMethod: string, isAccountUser: boolean) {
  const paymentMethods = localConstants.paymentMethods.filter(method => {
    return method.value === paymentMethod;
  });

  let method = paymentMethods.length === 1 ? paymentMethods[0].label : "–";
  if (isAccountUser && paymentMethod.indexOf("credit-card") > -1) {
    method = "Credit or debit card";
  }
  return method;
}

function getPaymentIntentPaymentMethod(paymentIntent: IPaymentIntent) {
  return paymentIntent.status === "paid"
    ? paymentIntent.payment?.payment_method
    : paymentIntent.original_requested_payment_method;
}

function getBankDetailsVerificationStatusClassNames(status?: string): string {
  if (status === "approved") {
    return "status-badge green-status";
  } else if (status === "declined") {
    return "status-badge red-status";
  }
  return "status-badge yellow-status";
}

function renderJSON(label: string, value: any) {
  try {
    return (
      <Card>
        <Label>{label}</Label>
        <Suspense fallback={<Loader.Inline />}>
          <JsonViewer value={JSON.parse(value)} />
        </Suspense>
      </Card>
    );
  } catch (e) {
    return <LabelWithValue label={label} value={value} />;
  }
}

function getDashboardFilterDetails() {
  const urlParams = new URLSearchParams(window.location.search);
  const dashboardFiltersParam = urlParams.get("dashboard_filters");
  const heading = urlParams.get("heading");
  const info = urlParams.get("info");
  const paymentMethod: any = urlParams.get("payment_method");
  const dashboardFilters = dashboardFiltersParam ? JSON.parse(dashboardFiltersParam) : {};
  const hasDashboardFilters = Object.keys(dashboardFilters).length > 0;
  const shouldShowUploadPOPButton = heading && paymentMethod && dashboardFilters ? false : true;
  const pan = urlParams.get("pan");
  const startDate: any = urlParams.get("start_date");
  const endDate = urlParams.get("end_date");
  return {
    heading,
    info,
    pan,
    paymentMethod,
    dashboardFilters,
    hasDashboardFilters,
    shouldShowUploadPOPButton,
    startDate,
    endDate
  };
}

function getPaymentMethodURL(paymentMethod: string, paymentMethodID: number) {
  const paymentURLs = [
    { method: "manual-eft", url: `/pop-efts/${paymentMethodID}` },
    { method: "instant-eft", url: `/instant-eft-payments/${paymentMethodID}` },
    { method: "credit-card", url: `/credit-card-payments/${paymentMethodID}` },
    { method: "capitec-pay", url: `/capitec-pay-payments/${paymentMethodID}` },
    { method: "statement", url: `/pop-statements/${paymentMethodID}` },
    { method: "scan-to-pay", url: `/qr-code-payments/${paymentMethodID}` },
    { method: "pay-shap", url: `/pay-shap-payments/${paymentMethodID}` },
    { method: "nedbank-direct-eft", url: `/nedbank-direct-eft-payments/${paymentMethodID}` },
    { method: "account-balance", url: `/accounts/${paymentMethodID}` }
  ];

  const payment = paymentURLs.find(method => method.method === paymentMethod);
  return payment ? payment.url : `/pop-statements/${paymentMethodID}`;
}

function isProd() {
  return config.api === "api.bobpay.co.za";
}

function getEnvironment(url: string) {
  if (url.includes("dev")) {
    return "dev";
  }
  if (url.includes("sandbox")) {
    return "sandbox";
  }

  return "prod";
}

function getBankNameFromValue(bankKey: string | undefined) {
  if (!bankKey) return bankKey;

  if (bankKey === "fnb") return "FNB";
  if (bankKey === "standard-bank") return "Standard Bank";
  if (bankKey === "bank-zero") return "Bank Zero";
  if (bankKey === "tyme-bank") return "TymeBank";

  return generalUtils.keyToHumanReadable(bankKey);
}

function renderCopyText(store: any, text: string, overrideCopyValue?: string) {
  return (
    <div
      className="text-primary cursor-pointer font-bold underline inline-block"
      onClick={() => copyText(store, overrideCopyValue ? overrideCopyValue : text)}
    >
      {text}
    </div>
  );
}

function copyText(store: any, value?: string) {
  navigator.clipboard.writeText(value ?? "");
  store.emitter.emit("showToast", {
    text: value + " copied to clipboard",
    variant: "success",
    autoHide: 3000
  });
}

async function onRefundPayment(
  store: IStore,
  refundPayment: (args: {
    id?: number;
    payment_method_id?: number;
    payment_method?: string;
  }) => void,
  id?: number,
  paymentMethod?: string
) {
  store.emitter.emit("showAlert", {
    title: "Refund payment?",
    body: "Are you sure you want to refund this payment?",
    showOkButton: true,
    showCancelButton: true,
    okButtonText: "Yes",
    okButtonVariant: "danger",
    return: async (confirm: boolean) => {
      if (confirm) {
        refundPayment(
          paymentMethod
            ? {
                payment_method_id: id,
                payment_method: paymentMethod
              }
            : { id: id }
        );
      }
    }
  });
}

function getRGBAValue(obj: { r: number; g: number; b: number; a: number }) {
  return `rgba(${obj.r}, ${obj.g}, ${obj.b}, ${obj.a})`;
}

function renderSelectedIndicator() {
  return (
    <div
      className={
        "absolute -top-4 -right-4 rounded-full items-center flex justify-center h-8 w-8 bg-primary-100 "
      }
    >
      <FontAwesomeIcon size="lg" icon="check" className="text-primary-500" />
    </div>
  );
}

function humanReadableUserType(userType: string) {
  if (userType === "jwt") {
    return userType.toUpperCase();
  } else if (userType === "api-key") {
    return "API Key";
  } else {
    return generalUtils.keyToHumanReadable(userType);
  }
}

function humanReadableBankName(bankName: string) {
  if (bankName === "nedbank-scan-to-pay") {
    return "Scan to Pay";
  } else if (bankName === "standard-bank") {
    return "Standard Bank";
  } else {
    return generalUtils.keyToHumanReadable(bankName);
  }
}

export {
  getBankNameFromValue,
  loadFromS3,
  uploadToS3,
  checkTime,
  isMaintenanceModePage,
  isAccountClosedPage,
  updateAccount,
  getAccount,
  getDocIDLink,
  generateAndOpenS3Resource,
  getBalanceClassNames,
  getPayoutRequestStatusClassNames,
  creditNoteNumber,
  invoiceNumber,
  addFiltersToArgs,
  addSortingToArgs,
  activeUser,
  activeUserFromStore,
  getGeneralData,
  getStatusClassNames,
  getInvoiceStatusClassNames,
  getPaymentIntentStatusClassNames,
  signedRequest,
  getSystemConfig,
  setUserSetting,
  isAccountUser,
  paymentHasBeenAccepted,
  checkBrowserVersion,
  getAccountNoteTypeClassNames,
  getBanksConfig,
  stopImpersonating,
  formatAccountNumber,
  getCreditCardType,
  getCommunicationLogStatusClassNames,
  getCapitecPayPaymentStatusClassNames,
  getSavedCardStatusClassName,
  getHumanReadablePaymentMethod,
  getPaymentIntentPaymentMethod,
  getCreditCardPaymentTypeClassName,
  getPaymentStatusClassName,
  getBankDetailsVerificationStatusClassNames,
  getPOPBankVerificationClassName,
  renderJSON,
  getDashboardFilterDetails,
  getPaymentMethodURL,
  isProd,
  getEnvironment,
  renderCopyText,
  onRefundPayment,
  getRGBAValue,
  renderSelectedIndicator,
  humanReadableUserType,
  humanReadableBankName
};
