import * as utils from "utils/utils";
import { IUser } from "interfaces/user.interface";
import { roleUtils } from "bob-group-ui-framework";
import { IStore } from "interfaces/store.interface";

const ROLE_PUBLIC = "Public";
const ROLE_ACCOUNT_USER = "AccountUser";
const ROLE_ACCOUNT_SUPER_USER = "AccountSuperUser";
const ROLE_SUPER_ADMIN = "SuperAdmin";

const searchBarPermissions = [
  "API_/accounts:GET",
  "API_/billing/invoices:GET",
  "API_/billing/credit-notes:GET"
];

const accountSpecificRoles = [
  {
    label: "Account User",
    value: 4
  },
  {
    label: "Account Superuser",
    value: 3
  }
];

function isAccountSuperUser(activeUser: IUser | undefined): boolean {
  return Boolean(
    activeUser && activeUser.account_id && roleUtils.hasRole(activeUser, [ROLE_ACCOUNT_SUPER_USER])
  );
}

function isAccountUser(activeUser: IUser | undefined): boolean {
  return Boolean(
    activeUser && activeUser.account_id && roleUtils.hasRole(activeUser, [ROLE_ACCOUNT_USER])
  );
}

function getRole(user: IUser | undefined, roles: any[]) {
  if (!user) return;

  const rolesFound = roles.filter(role => role.id === user.role_id);

  if (rolesFound.length > 0) {
    return rolesFound[0];
  }
}

function allStaffRoles(roles: any[], activeUser: IUser | undefined): any[] {
  let filteredRoles = roles.filter(
    role =>
      role.name !== ROLE_ACCOUNT_USER &&
      role.name !== ROLE_ACCOUNT_SUPER_USER &&
      role.name !== ROLE_PUBLIC
  );

  if (!isSuperAdmin(activeUser)) {
    filteredRoles = filteredRoles.filter(role => role.value !== ROLE_SUPER_ADMIN);
  }

  return filteredRoles;
}

function isStaff(activeUser: IUser | undefined): boolean {
  return !isAccountUserOrSuper(activeUser) && !roleUtils.hasRole(activeUser, [ROLE_PUBLIC]);
}

function isSuperAdmin(activeUser: IUser | undefined): boolean {
  return Boolean(activeUser && roleUtils.hasRole(activeUser, [ROLE_SUPER_ADMIN]));
}

function isAccountUserOrSuper(activeUser: IUser | undefined): boolean {
  return Boolean(
    activeUser &&
      activeUser.account_id &&
      roleUtils.hasRole(activeUser, [ROLE_ACCOUNT_USER, ROLE_ACCOUNT_SUPER_USER])
  );
}

function userHasAdminSystemAccess(props: { store: IStore }) {
  const { impersonated_user_id } = props.store;
  const activeUser = utils.activeUserFromStore(props.store);
  if (!impersonated_user_id && activeUser && isStaff(activeUser)) {
    return true;
  }
  return false;
}

export {
  allStaffRoles,
  userHasAdminSystemAccess,
  isAccountSuperUser,
  isAccountUser,
  isAccountUserOrSuper,
  isSuperAdmin,
  getRole,
  isStaff,
  accountSpecificRoles,
  searchBarPermissions,
  ROLE_PUBLIC,
  ROLE_ACCOUNT_SUPER_USER,
  ROLE_ACCOUNT_USER,
  ROLE_SUPER_ADMIN
};
