import * as utils from "utils/utils";
import { IUser } from "interfaces/user.interface";
import { IStore } from "interfaces/store.interface";

function getAccessToken() {
  return localStorage.getItem("access_token") ?? null;
}

function setAccessToken(token: any) {
  localStorage.setItem("access_token", token);
}

function logOut(store: IStore) {
  store.emitter.emit("logOut", false);
}

async function logIn(
  store: IStore,
  emailOrUserID: string | number,
  password: string,
  history: any
) {
  // Try jwt login
  const args: any = {
    password
  };

  if (typeof emailOrUserID === "string") args.email = emailOrUserID;
  else args.user_id = emailOrUserID;

  const jwtLoginResult = await utils.signedRequest(store, "/login", "POST", args);
  if (jwtLoginResult.ok && jwtLoginResult.data.access_token) {
    setAccessToken(jwtLoginResult.data.access_token);
    redirectAfterLogin(history);
    return {
      loginSuccess: true,
      user: jwtLoginResult,
      error: null
    };
  } else {
    const error = JSON.parse(jwtLoginResult.error.message).message;
    if (
      error.indexOf("password is incorrect") > -1 ||
      error.indexOf("user password is not set") > -1
    ) {
      return {
        loginSuccess: false,
        user: null,
        error: error
      };
    } else {
      return {
        loginSuccess: false,
        user: null,
        error: error
      };
    }
  }
}

async function refreshToken(store: IStore, activeUser: IUser) {
  if (
    localStorage.getItem("isRefreshing") !== null &&
    // @ts-ignore
    !JSON.parse(localStorage.getItem("isRefreshing"))
  ) {
    localStorage.setItem("isRefreshing", "true");

    const result = await utils.signedRequest(store, "/login/refresh", "POST", {
      user_id: activeUser.id
    });
    localStorage.setItem("isRefreshing", "false");

    if (result.ok && result?.data?.access_token) {
      setAccessToken(result?.data?.access_token);
    }
  }
}

function redirectAfterLogin(history: any) {
  if (history) {
    const urlParams = new URLSearchParams(window.location.search);
    const redirect = urlParams.get("redirect");
    if (redirect) {
      history.go(redirect);
    } else if (
      window.location.pathname.indexOf("/login") >= 0 ||
      window.location.pathname.indexOf("/register") >= 0
    ) {
      history.go("/");
    }
  }
}

async function resetPassword(store: IStore, userID?: any, email?: string) {
  const args: any = {};

  if (userID) {
    args.user_id = userID;
  }

  if (email) {
    args.email = email;
  }

  return await utils.signedRequest(store, "/users/reset-password", "POST", args);
}

async function updatePassword(store: IStore, password: string, userID?: any) {
  const args: any = { password };

  if (userID) {
    args.user = userID;
  }

  return await utils.signedRequest(store, "/users/update-password", "POST", args);
}

async function resendInvitation(store: IStore, email: any) {
  return await resetPassword(store, undefined, email);
}

async function verifyAccountFromEmailLink(
  store: IStore,
  verificationCode: string,
  email?: string,
  userID?: number,
  password?: string
) {
  const args: any = {
    verification_code: verificationCode
  };

  if (email) {
    args.email = email;
  }

  if (userID) {
    args.user_id = userID;
  }

  if (password) {
    args.password = password;
  }

  return await utils.signedRequest(store, "/login", "POST", args);
}

export {
  logIn,
  logOut,
  getAccessToken,
  refreshToken,
  resetPassword,
  updatePassword,
  resendInvitation,
  verifyAccountFromEmailLink
};
