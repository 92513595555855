import { Card, PageHeading, SectionHeading, withError } from "bob-group-ui-framework";
import "pages/auth/MaintenancePage.scss";

interface IProps {
  error?: any;
}

function MaintenancePage(props: IProps) {
  return (
    <div className="maintenance">
      <Card>
        <PageHeading isCenter>
          {props.error ? props.error : "Hi there, We’re busy with some scheduled maintenance"}
        </PageHeading>
        <div className="text-center">
          <img src="/maintenance.png" alt="Maintenance mode" />
        </div>
        <SectionHeading isCenter>Operations will continue again shortly</SectionHeading>
      </Card>
    </div>
  );
}

export default withError(MaintenancePage);
