let host = window.location.host.replace("www.", "");

const accounts: any = {
  "dev.bobpay.co.za": {
    title: "Bob Pay :: Dev",
    api: "api.dev.bobpay.co.za",
    pop_email: "proof-dev@bobpay.co.za",
    raygunKey: "6pBdEdRFutZ0ocjvkoA"
  },
  "sandbox.bobpay.co.za": {
    title: "Bob Pay :: Sandbox",
    api: "api.sandbox.bobpay.co.za",
    pop_email: "proof-sandbox@bobpay.co.za",
    raygunKey: "9pDKp8isjkZgaElEK4Mow"
  },
  "my.bobpay.co.za": {
    title: "Bob Pay",
    api: "api.bobpay.co.za",
    pop_email: "proof@bobpay.co.za",
    raygunKey: "ULB43b7sLCDXZHHOAPs5Tg"
  }
};

if (!accounts[host]) {
  host = "dev.bobpay.co.za";
}

const account = accounts[host];

export default {
  accounts,
  region: "af-south-1",
  ...account
};
