import { IBankConfig } from "interfaces/bank.interface";

export const bankConfigs: IBankConfig[] = [
  {
    value: "absa",
    color: "#be0028",
    payShapDetails: {
      fieldsToDisplay: [
        { value: "amount", label: "Amount" },
        { value: "pay_shap_id", label: "PayShap ID" },
        { value: "bank", label: "Bank" },
        { value: "reference", label: "Reference" }
      ],
      payShapIDCleanupFunction: (ref: string) => {
        return ref.substring(0, ref.indexOf("@"));
      },
      shouldShowDetailedInstructions: true
    },
    manualEFTLabels: {
      branchCode: "Branch",
      accountNumber: "Account number",
      accountType: "",
      reference: "Reference",
      amount: "Amount",
      beneficiaryName: "Beneficiary name"
    },
    instantEFTLoginConfig: {
      identifierLabel: "Account number",
      identifierInputType: "number",
      passwordLabel: "Pin",
      passwordInputType: "number"
    },
    instantEFT: {
      actions: [
        "login",
        "enter_sure_phrase",
        "sure_phrase",
        "select_account",
        "account_selected",
        "approve_beneficiary",
        "approve_payment",
        "payment_completed",
        "error"
      ],
      steps: [
        {
          label: "Log in",
          labelWhileLoading: "Logging in...",
          isStepCompleted: (indices: any) => {
            return indices.action >= indices.select_account;
          },
          isStepInProgress: (indices: any) => {
            return indices.action < indices.select_account;
          }
        },
        {
          label: "Select account",
          labelWhileLoading: "Select account",
          isStepCompleted: (indices: any) => {
            return indices.action > indices.select_account;
          },
          isStepInProgress: (indices: any) => {
            return indices.action === indices.select_account;
          }
        },
        {
          label: "Create Bob Pay as a beneficiary",
          labelWhileLoading: "Creating beneficiary...",
          isStepCompleted: (indices: any) => {
            return indices.action > indices.approve_beneficiary;
          },
          isStepInProgress: (indices: any) => {
            return indices.action === indices.approve_beneficiary;
          }
        },
        {
          label: "Process payment",
          labelWhileLoading: "Processing payment...",
          isStepCompleted: (indices: any) => {
            return indices.action > indices.approve_payment;
          },
          isStepInProgress: (indices: any) => {
            return indices.action === indices.approve_payment;
          }
        }
      ]
    }
  },
  {
    value: "fnb",
    color: "#01aaad",
    payShapDetails: {
      fieldsToDisplay: [
        { value: "pay_shap_id", label: "ShapID" },
        { value: "amount", label: "Amount" },
        { value: "reference", label: "Recipient ref." }
      ],
      shouldShowDetailedInstructions: true
    },
    manualEFTLabels: {
      branchCode: "Branch code",
      accountNumber: "Account number",
      accountType: "Account type",
      reference: "Their reference",
      amount: "Amount",
      beneficiaryName: "Recipient name"
    },
    instantEFT: {
      actions: [
        "login",
        "select_profile",
        "profile_selected",
        "select_account",
        "account_selected",
        "approve_payment",
        "payment_completed",
        "error"
      ],
      steps: [
        {
          label: "Log in",
          labelWhileLoading: "Logging in...",
          isStepCompleted: (indices: any) => {
            return indices.action > indices.login;
          },
          isStepInProgress: (indices: any) => {
            return indices.action === indices.login;
          }
        },
        {
          label: "Select profile",
          labelWhileLoading: "Select profile",
          isStepCompleted: (indices: any) => {
            return indices.action > indices.select_profile;
          },
          isStepInProgress: (indices: any) => {
            return indices.action === indices.select_profile;
          }
        },
        {
          label: "Select account",
          labelWhileLoading: "Select account",
          isStepCompleted: (indices: any) => {
            return indices.action > indices.select_account;
          },
          isStepInProgress: (indices: any) => {
            return (
              indices.action === indices.profile_selected ||
              indices.action === indices.select_account
            );
          }
        },
        {
          label: "Process payment",
          labelWhileLoading: "Processing payment...",
          isStepCompleted: (indices: any) => {
            return indices.action > indices.approve_payment;
          },
          isStepInProgress: (indices: any) => {
            return indices.action >= indices.account_selected;
          }
        }
      ]
    }
  },
  {
    value: "standard-bank",
    color: "#0033a1",
    payShapDetails: {
      fieldsToDisplay: [
        { value: "pay_shap_id", label: "ShapID" },
        { value: "amount", label: "Amount" },
        { value: "reference", label: "Their reference" }
      ],
      shouldShowDetailedInstructions: true
    },
    manualEFTLabels: {
      branchCode: "Branch",
      accountNumber: "Account number",
      accountType: "",
      reference: "Reference",
      amount: "Amount",
      beneficiaryName: "Beneficiary name"
    },
    instantEFT: {
      actions: [
        "login",
        "approve_login",
        "enter_login_otp",
        "login_otp",
        "select_account",
        "account_selected",
        "enter_otp",
        "otp",
        "payment_completed",
        "error"
      ],
      steps: [
        {
          label: "Log in",
          labelWhileLoading: "Logging in...",
          isStepCompleted: (indices: any) => {
            return indices.action >= indices.select_account;
          },
          isStepInProgress: (indices: any) => {
            return indices.action < indices.select_account;
          }
        },
        {
          label: "Select account",
          labelWhileLoading: "Select account",
          isStepCompleted: (indices: any) => {
            return indices.action > indices.select_account;
          },
          isStepInProgress: (indices: any) => {
            return indices.action === indices.select_account;
          }
        },
        {
          label: "Confirm payment OTP",
          labelWhileLoading: "Waiting for OTP confirmation...",
          isStepCompleted: (indices: any) => {
            return indices.action >= indices.otp;
          },
          isStepInProgress: (indices: any) => {
            return indices.action < indices.otp && indices.action >= indices.enter_otp;
          }
        },
        {
          label: "Process payment",
          labelWhileLoading: "Processing payment...",
          isStepCompleted: (indices: any) => {
            return indices.action > indices.payment_completed;
          },
          isStepInProgress: (indices: any) => {
            return indices.action === indices.payment_completed;
          }
        }
      ]
    }
  },
  {
    value: "nedbank",
    color: "#003e2b",
    payShapDetails: {
      fieldsToDisplay: [
        { value: "recipient_name", label: "Recipient name" },
        { value: "pay_shap_id", label: "ShapID" },
        { value: "amount", label: "Amount" },
        { value: "reference", label: "Recipient reference" }
      ],
      shouldShowDetailedInstructions: true
    },
    manualEFTLabels: {
      branchCode: "Branch code",
      accountNumber: "Account number",
      accountType: "",
      reference: "Their reference",
      amount: "Amount",
      beneficiaryName: "Recipient"
    },
    instantEFT: {
      actions: [
        "login",
        "approve_login",
        "select_account",
        "account_selected",
        "approve_payment",
        "payment_completed",
        "error"
      ],
      steps: [
        {
          label: "Log in",
          labelWhileLoading: "Logging in...",
          isStepCompleted: (indices: any) => {
            return indices.action >= indices.approve_login;
          },
          isStepInProgress: (indices: any) => {
            return indices.action < indices.select_account;
          }
        },
        {
          label: "Select account",
          labelWhileLoading: "Select account",
          isStepCompleted: (indices: any) => {
            return indices.action > indices.select_account;
          },
          isStepInProgress: (indices: any) => {
            return indices.action === indices.select_account;
          }
        },
        {
          label: "Process payment",
          labelWhileLoading: "Processing payment...",
          isStepCompleted: (indices: any) => {
            return indices.action > indices.approve_payment;
          },
          isStepInProgress: (indices: any) => {
            return indices.action === indices.approve_payment;
          }
        }
      ]
    }
  },
  {
    value: "discovery-bank",
    color: "#a9774c",
    payShapDetails: {
      fieldsToDisplay: [
        { value: "pay_shap_id", label: "ShapID" },
        { value: "amount", label: "Amount" },
        { value: "reference", label: "Recipient reference" }
      ],
      shouldShowDetailedInstructions: true
    },
    manualEFTLabels: {
      branchCode: "Branch code",
      accountNumber: "Account number",
      accountType: "",
      reference: "Recipient reference",
      amount: "Amount",
      beneficiaryName: "Name"
    },
    instantEFT: {
      actions: [
        "login",
        "approve_login",
        "select_account",
        "account_selected",
        "approve_payment",
        "payment_completed",
        "error"
      ],
      steps: [
        {
          label: "Log in",
          labelWhileLoading: "Logging in...",
          isStepCompleted: (indices: any) => {
            return indices.action >= indices.select_account;
          },
          isStepInProgress: (indices: any) => {
            return indices.action < indices.select_account;
          }
        },
        {
          label: "Select account",
          labelWhileLoading: "Select account",
          isStepCompleted: (indices: any) => {
            return indices.action > indices.select_account;
          },
          isStepInProgress: (indices: any) => {
            return indices.action === indices.select_account;
          }
        },
        {
          label: "Process payment",
          labelWhileLoading: "Processing payment...",
          isStepCompleted: (indices: any) => {
            return indices.action > indices.approve_payment;
          },
          isStepInProgress: (indices: any) => {
            return (
              indices.action >= indices.approve_payment &&
              indices.action < indices.payment_completed
            );
          }
        }
      ]
    }
  },
  {
    value: "capitec",
    color: "#04375B",
    manualEFTLabels: {
      branchCode: "Branch code",
      accountNumber: "Account number",
      accountType: "",
      reference: "Reference",
      amount: "Amount",
      beneficiaryName: "Beneficiary name"
    },
    instantEFT: {
      actions: [
        "login",
        "approve_login",
        "select_account",
        "account_selected",
        "approve_beneficiary",
        "approve_payment",
        "payment_completed",
        "error"
      ],
      steps: [
        {
          label: "Log in",
          labelWhileLoading: "Logging in...",
          isStepCompleted: (indices: any) => {
            return indices.action >= indices.approve_login;
          },
          isStepInProgress: (indices: any) => {
            return indices.action < indices.select_account;
          }
        },
        {
          label: "Select account",
          labelWhileLoading: "Select account",
          isStepCompleted: (indices: any) => {
            return indices.action > indices.select_account;
          },
          isStepInProgress: (indices: any) => {
            return indices.action === indices.select_account;
          }
        },
        {
          label: "Create Bob Pay as a beneficiary",
          labelWhileLoading: "Creating beneficiary...",
          isStepCompleted: (indices: any) => {
            return indices.action > indices.approve_beneficiary;
          },
          isStepInProgress: (indices: any) => {
            return indices.action === indices.approve_beneficiary;
          }
        },
        {
          label: "Process payment",
          labelWhileLoading: "Processing payment...",
          isStepCompleted: (indices: any) => {
            return indices.action > indices.approve_payment;
          },
          isStepInProgress: (indices: any) => {
            return indices.action === indices.approve_payment;
          }
        }
      ]
    }
  },
  {
    value: "investec",
    color: "#4d87c9",
    payShapDetails: {
      fieldsToDisplay: [
        { value: "pay_shap_id", label: "ShapID" },
        { value: "bank", label: "Bank" },
        { value: "amount", label: "Amount" },
        { value: "reference", label: "Reference" }
      ],
      shouldShowDetailedInstructions: true
    },
    manualEFTLabels: {
      branchCode: "Branch",
      accountNumber: "Account number",
      accountType: "Account type",
      reference: "Their reference",
      amount: "Amount",
      beneficiaryName: "Beneficiary name"
    },
    instantEFT: {
      actions: [
        "login",
        "approve_login",
        "select_account",
        "account_selected",
        "payment_completed",
        "error"
      ],
      steps: [
        {
          label: "Log in",
          labelWhileLoading: "Logging in...",
          isStepCompleted: (indices: any) => {
            return indices.action >= indices.approve_login;
          },
          isStepInProgress: (indices: any) => {
            return indices.action < indices.select_account;
          }
        },
        {
          label: "Select account",
          labelWhileLoading: "Select account",
          isStepCompleted: (indices: any) => {
            return indices.action > indices.select_account;
          },
          isStepInProgress: (indices: any) => {
            return indices.action === indices.select_account;
          }
        },
        {
          label: "Process payment",
          labelWhileLoading: "Processing payment...",
          isStepCompleted: (indices: any) => {
            return indices.action > indices.account_selected;
          },
          isStepInProgress: (indices: any) => {
            return indices.action === indices.account_selected;
          }
        }
      ]
    }
  },
  {
    value: "bank-zero",
    color: "#343434",
    manualEFTLabels: {
      branchCode: "Branch",
      accountNumber: "Account number",
      accountType: "Account Type",
      reference: "Reference",
      amount: "Amount",
      beneficiaryName: "Beneficiary name"
    }
  },
  {
    value: "tyme-bank",
    color: "#fcbd0e",
    manualEFTLabels: {
      branchCode: "Branch",
      accountNumber: "Recipient's account number",
      accountType: "",
      reference: "Their reference",
      amount: "Amount",
      beneficiaryName: "Name"
    },
    instantEFT: {
      actions: [
        "login",
        "enter_login_otp",
        "login_otp",
        "enter_otp",
        "otp",
        "payment_completed",
        "error"
      ],
      steps: [
        {
          label: "Log in",
          labelWhileLoading: "Logging in...",
          isStepCompleted: (indices: any) => {
            return indices.action >= indices.enter_login_otp;
          },
          isStepInProgress: (indices: any) => {
            return indices.action < indices.enter_login_otp;
          }
        },
        {
          label: "Confirm payment OTP",
          labelWhileLoading: "Waiting for OTP confirmation...",
          isStepCompleted: (indices: any) => {
            return indices.action >= indices.otp;
          },
          isStepInProgress: (indices: any) => {
            return indices.action < indices.otp && indices.action >= indices.enter_otp;
          }
        },
        {
          label: "Process payment",
          labelWhileLoading: "Processing payment...",
          isStepCompleted: (indices: any) => {
            return indices.action >= indices.payment_completed;
          },
          isStepInProgress: (indices: any) => {
            return indices.action > indices.enter_otp && indices.action < indices.payment_completed;
          }
        }
      ]
    }
  }
];
