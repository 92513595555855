import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory } from "react-router-dom";
import * as localRoleUtils from "utils/roleUtils";
import {
  Button,
  NavItem,
  NavItemDivider,
  navUtils,
  interfaces,
  roleUtils,
  useStore,
  SearchInput,
  withError
} from "bob-group-ui-framework";

import { IStore } from "interfaces/store.interface";

interface IProps {
  onMounted: any;
  closeMenu: any;
  isNavbarCollapsed: any;
  activeUser: any;
  toggleNavbarCollapsed: any;
}

function AdminNavigation(props: IProps) {
  const store: IStore = useStore();
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchingError, setSearchingError] = useState<string>("");

  useEffect(() => {
    props.onMounted();
  }, []);

  const onSearchChanged = (val: any) => {
    setSearchTerm(val);
    setSearchingError("");
  };

  function search() {
    if (!searchTerm) return;

    const upperSearchTerm = searchTerm.toUpperCase().trim();

    setIsSearching(true);
    setSearchingError("");

    history.push("/search-results?term=" + upperSearchTerm);

    setSearchTerm("");
    setIsSearching(false);
  }

  /* -------------------------------- */
  /* RENDER METHODS */
  /* -------------------------------- */

  function renderNavSectionItems(section: any[]) {
    return section.map((item: any, itemIndex: number) => {
      return (
        <React.Fragment key={itemIndex}>
          {item.hasAccess && item.displayCondition && (
            // @ts-ignore
            <Link to={item.path} onClick={() => props.closeMenu()}>
              <NavItem item={item} isNavbarCollapsed={props.isNavbarCollapsed} />
            </Link>
          )}
        </React.Fragment>
      );
    });
  }

  function renderNavSections() {
    const { activeUser } = props;

    const navSections: interfaces.INavItem[][] = [
      [
        {
          displayName: "Exceptions",
          icon: "gauge",
          path: "/exceptions",
          hasAccess: true,
          displayCondition: true
        },
        {
          displayName: "Payments",
          icon: "bullseye",
          path: "/payments",
          hasAccess: true,
          displayCondition: true
        },
        {
          displayName: "Saved cards",
          icon: "credit-card",
          path: "/saved-cards",
          hasAccess: true,
          displayCondition: true
        }
      ],
      [
        {
          displayName: "Billing",
          icon: "file-invoice-dollar",
          path: "/billing",
          hasAccess: roleUtils.hasPermission(activeUser, "API_/billing/transactions:GET"),
          displayCondition: true
        },
        {
          displayName: "Reports",
          icon: "file-invoice",
          path: "/reports",
          hasAccess: roleUtils.hasPermission(activeUser, "API_/reports:GET"),
          displayCondition: true
        },
        {
          displayName: "Accounts",
          icon: "star",
          path: "/accounts",
          hasAccess: roleUtils.hasPermission(activeUser, "API_/accounts:PATCH"),
          displayCondition: true
        },
        {
          displayName: "Pending applications",
          icon: "store",
          path: "/pending-applications",
          hasAccess: roleUtils.hasPermission(activeUser, "API_/accounts:PATCH"),
          displayCondition: true
        },
        {
          displayName: "Users",
          icon: "users",
          path: "/users",
          hasAccess: roleUtils.hasPermission(activeUser, "API_/users:POST"),
          displayCondition: true
        }
      ],
      [
        {
          displayName: "System config",
          icon: "cog",
          path: "/system-config",
          hasAccess: roleUtils.hasPermission(activeUser, "API_/config:POST"),
          displayCondition: true
        }
      ]
    ];

    return navSections.map((section, sectionIndex) => {
      const displayBreak =
        navUtils.checkIfUserHasAccessToNavSection(section) &&
        navUtils.checkIfUserHasAccessToNavSection(navSections[sectionIndex + 1]); // If user has access to both current and next section, add <NavItemDivider />

      return (
        <div key={sectionIndex} className="nav-sections">
          {renderNavSectionItems(section)}
          {displayBreak ? <NavItemDivider /> : null}
        </div>
      );
    });
  }

  function renderNavSearch(
    searchTerm: string | any[],
    isSearching: boolean | undefined,
    searchingError: {} | null | undefined
  ) {
    return (
      <>
        {roleUtils.hasAllPermissions(props.activeUser, localRoleUtils.searchBarPermissions) && (
          <>
            <div className="nav-reference-search">
              {props.isNavbarCollapsed ? (
                <div
                  style={{
                    height: "45px",
                    paddingBottom: !props.isNavbarCollapsed ? "10px" : "47px"
                  }}
                >
                  <FontAwesomeIcon
                    icon="search"
                    onClick={() => props.toggleNavbarCollapsed()}
                    className="ml-2 mt-3 text-gray-500 cursor-pointer"
                  />
                </div>
              ) : (
                <div className="flex flex-row justify-center items-center">
                  <SearchInput
                    onChange={(event: any) => onSearchChanged(event.target.value)}
                    onKeyPress={(event: { key: string }) => {
                      if (event.key === "Enter" || event.key === "NumpadEnter") {
                        search();
                      }
                    }}
                    type="text"
                    isDisabled={isSearching}
                    // @ts-ignore
                    className="nav-reference-search-input"
                    containerClassName="m-0"
                    placeholder="Search"
                    value={searchTerm}
                  />
                  <Button.Icon
                    isDisabled={isSearching || searchTerm.length === 0}
                    onClick={search}
                    bgColor="primary"
                    id="nav_search_button"
                    className="w-12 ml-2"
                    iconSize="sm"
                    isLoading={isSearching}
                    icon="chevron-right"
                  />
                </div>
              )}

              {searchingError && !props.isNavbarCollapsed && (
                // @ts-ignore
                <div className="text-red px-2 pt-2 text-xs font-semibold">{searchingError}</div>
              )}
              {!props.isNavbarCollapsed && (
                <div className="divide-x-2" style={{ marginBottom: 10, marginTop: 7 }} />
              )}
            </div>
          </>
        )}
      </>
    );
  }

  function render() {
    if (!store) return null;

    return (
      <>
        {renderNavSearch(searchTerm, isSearching, searchingError)}
        {renderNavSections()}
      </>
    );
  }

  return render();
}

export default withError(AdminNavigation);
