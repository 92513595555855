import * as roleUtils from "utils/roleUtils";
import React, { useEffect } from "react";
import { IAccount } from "interfaces/account.interface";
import { IUser } from "interfaces/user.interface";
// @ts-ignore

import { Link } from "react-router-dom";
import {
  NavItem,
  NavItemDivider,
  navUtils,
  interfaces,
  useStore,
  withError
} from "bob-group-ui-framework";

import { IStore } from "interfaces/store.interface";

interface IProps {
  isNavbarCollapsed: boolean;
  closeMenu: any;
  activeUser: IUser | undefined;
  onMounted: () => void;
}

function AccountsNavigation(props: IProps) {
  const store: IStore = useStore();

  useEffect(() => {
    props.onMounted();
  }, []);

  /* -------------------------------- */
  /* RENDER METHODS */
  /* -------------------------------- */

  function renderNavSectionItems(section: any) {
    return section.map((item: any, itemIndex: number) => {
      return (
        <React.Fragment key={itemIndex}>
          {item.hasAccess && item.displayCondition && (
            // @ts-ignore
            <Link to={item.path} onClick={() => props.closeMenu()}>
              <NavItem item={item} isNavbarCollapsed={props.isNavbarCollapsed} />
            </Link>
          )}
        </React.Fragment>
      );
    });
  }

  function renderNavSections(account: IAccount, activeUser: IUser) {
    const navSections: interfaces.INavItem[][] = [
      [
        {
          displayName: "Dashboard",
          icon: "chart-line",
          path: "/dashboard",
          hasAccess: account?.can_receive_payments && roleUtils.isAccountUserOrSuper(activeUser),
          displayCondition: true
        },
        {
          displayName: "Transactions",
          icon: "bullseye",
          path: "/payments",
          hasAccess: true,
          displayCondition: true
        },
        {
          displayName: "Payouts",
          icon: "money-bill",
          path: "/payout-requests",
          hasAccess: roleUtils.isAccountSuperUser(activeUser),
          displayCondition: Boolean(activeUser.account_id)
        },
        {
          displayName: "Account",
          icon: "star",
          path: "/account",
          hasAccess: roleUtils.isAccountSuperUser(activeUser),
          displayCondition: Boolean(activeUser.account_id)
        }
      ],
      [
        {
          displayName: "Settings",
          icon: "cog",
          path: "/settings",
          hasAccess: account?.can_receive_payments && roleUtils.isAccountSuperUser(activeUser),
          displayCondition: Boolean(activeUser.account_id)
        }
      ]
    ];

    return navSections.map((section: any, sectionIndex: number) => {
      const displayBreak =
        navUtils.checkIfUserHasAccessToNavSection(section) &&
        navUtils.checkIfUserHasAccessToNavSection(navSections[sectionIndex + 1]); // If user has access to both current and next section, add <NavItemDivider /> element
      return (
        <React.Fragment key={sectionIndex}>
          {renderNavSectionItems(section)}
          {displayBreak ? <NavItemDivider /> : null}
        </React.Fragment>
      );
    });
  }

  function render() {
    if (!store) return null;

    const account = store.account;

    if (props.activeUser) {
      return renderNavSections(account, props.activeUser);
    }

    const loginItem: interfaces.INavItem = {
      path: "/login",
      displayName: "Log in",
      icon: "sign-in-alt"
    };

    // Not logged in
    return (
      <>
        {/* @ts-ignore */}
        <Link to="/login" onClick={() => props.closeMenu()}>
          {/* @ts-ignore */}
          <NavItem item={loginItem} isNavbarCollapsed={props.isNavbarCollapsed} />
        </Link>
      </>
    );
  }

  return render();
}

// @ts-ignore
export default withError(AccountsNavigation);
